import React from 'react';
// Modules
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/node';
import Image from 'next/image';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
// Components
import MobileDrawer from './MobileDrawer';
import NavLink from './NavLink';
// Contexts
import { useAuth } from '../../contexts/AuthContext';
// Firebase
import firebase from '../../firebase/client';
// Utils
import { isAdmin } from '../../utils/helpers';
import routes from '../../utils/routes';

const Nav: React.FC = () => {
  const { currentUser, role } = useAuth();

  const router = useRouter();

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  async function onClickLogOut() {
    try {
      await Router.push(routes.home.href);

      await firebase.auth().signOut();
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return (
    <Box
      as="header"
      bgColor="whiteAlpha.700"
      left="0"
      position="fixed"
      right="0"
      sx={{ backdropFilter: 'blur(16px) saturate(160%)' }}
      top="0"
      zIndex="sticky"
    >
      <Container
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        maxW="container.lg"
        p="4"
      >
        <HStack spacing={['4', '0']}>
          <IconButton
            aria-label="Menu"
            display={['flex', 'none']}
            icon={<HamburgerIcon />}
            onClick={() => setDrawerOpen(true)}
            size="lg"
            variant="ghost"
          />
          <MobileDrawer isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)} />
          <Link href={routes.home.href}>
            <a className="img-wrapper">
              <Image height={48} src="/images/logo.png" width={48} />
            </a>
          </Link>
        </HStack>
        <HStack as="nav" display={['none', 'flex']} spacing="8">
          <NavLink
            href="https://shopee.co.id/utamastationerytulungagung"
            rel="noopener noreferrer"
            target="_blank"
          >
            Belanja
          </NavLink>
          <NavLink
            active={[
              routes.promotion.href,
              routes.promotionInfo.href,
            ].includes(router.pathname)}
            href={routes.promotion.href}
          >
            Promo
          </NavLink>
          <NavLink active={router.pathname === routes.contact.href} href={routes.contact.href}>
            Kontak Kami
          </NavLink>
          {currentUser ? (
            <Box>
              <Menu isLazy placement="bottom-end">
                <MenuButton
                  as={Avatar}
                  cursor="pointer"
                  name={currentUser.displayName ?? undefined}
                  size="sm"
                  sx={{
                    span: {
                      display: 'none',
                    },
                  }}
                  src={currentUser.photoURL ?? undefined}
                />
                <MenuList>
                  {isAdmin(role) && (
                    <Link href={routes.adminDashboard.href} passHref>
                      <MenuItem as="a">Admin</MenuItem>
                    </Link>
                  )}
                  <Link href={routes.myAccount.href} passHref>
                    <MenuItem as="a">Akun Saya</MenuItem>
                  </Link>
                  <MenuItem onClick={onClickLogOut}>Keluar</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <HStack spacing="4">
              <Link href={routes.logIn.href} passHref>
                <Button as="a" colorScheme="primary" textColor="black">
                  Masuk
                </Button>
              </Link>
              <Link href={routes.signUp.href} passHref>
                <Button as="a" colorScheme="secondary">
                  Daftar
                </Button>
              </Link>
            </HStack>
          )}
        </HStack>
      </Container>
    </Box>
  );
};

export default Nav;
