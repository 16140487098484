import React from 'react';
// Modules
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Container,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/node';
import Image from 'next/image';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
// Components
import AdminMobileDrawer from './AdminMobileDrawer';
import NavLink from './NavLink';
// Contexts
import { useAuth } from '../../contexts/AuthContext';
// Firebase
import firebase from '../../firebase/client';
// Utils
import { isSuperAdmin } from '../../utils/helpers';
import routes from '../../utils/routes';

const AdminNav: React.FC = () => {
  const { currentUser, role } = useAuth();

  const router = useRouter();

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  async function onClickLogOut() {
    try {
      await Router.push(routes.home.href);

      await firebase.auth().signOut();
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  return (
    <Box
      as="header"
      bgColor="whiteAlpha.700"
      left="0"
      position="fixed"
      right="0"
      sx={{ backdropFilter: 'blur(16px) saturate(160%)' }}
      top="0"
      zIndex="sticky"
    >
      <Container
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        maxW="container.lg"
        p="4"
      >
        <HStack spacing={['4', '0']}>
          <IconButton
            aria-label="Menu"
            display={['flex', 'none']}
            icon={<HamburgerIcon />}
            onClick={() => setDrawerOpen(true)}
            size="lg"
            variant="ghost"
          />
          <AdminMobileDrawer isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)} />
          <Link href={routes.adminDashboard.href}>
            <a className="img-wrapper">
              <Image height={48} src="/images/logo.png" width={48} />
            </a>
          </Link>
        </HStack>
        <HStack as="nav" display={['none', 'flex']} spacing="8">
          {isSuperAdmin(role) && (
            <NavLink
              active={[
                routes.adminFeature.href,
              ].includes(router.pathname)}
              href={routes.adminFeature.href}
            >
              Data Fitur
            </NavLink>
          )}
          <NavLink
            active={[
              routes.adminCustomer.href,
              routes.adminCustomerInfo.href,
            ].includes(router.pathname)}
            href={routes.adminCustomer.href}
          >
            Data Pelanggan
          </NavLink>
          <NavLink
            active={[
              routes.adminSale.href,
              routes.adminSaleCreate.href,
            ].includes(router.pathname)}
            href={routes.adminSale.href}
          >
            Data Penjualan
          </NavLink>
          <NavLink
            active={[
              routes.adminPointUsage.href,
              routes.adminPointUsageCreate.href,
            ].includes(router.pathname)}
            href={routes.adminPointUsage.href}
          >
            Data Penukaran Poin
          </NavLink>
          {isSuperAdmin(role) && (
            <NavLink
              active={[
                routes.adminProduct.href,
              ].includes(router.pathname)}
              href={routes.adminProduct.href}
            >
              Data Produk
            </NavLink>
          )}
          <NavLink
            active={[
              routes.adminPromotion.href,
              routes.adminPromotionCreate.href,
              routes.adminPromotionInfo.href,
              routes.adminPromotionUpdate.href,
            ].includes(router.pathname)}
            href={routes.adminPromotion.href}
          >
            Data Promo
          </NavLink>
          <Box>
            <Menu isLazy placement="bottom-end">
              <MenuButton
                as={Avatar}
                cursor="pointer"
                name={currentUser?.displayName ?? undefined}
                size="sm"
                sx={{
                  span: {
                    display: 'none',
                  },
                }}
                src={currentUser?.photoURL ?? undefined}
              />
              <MenuList>
                <Link href={routes.home.href} passHref>
                  <MenuItem as="a">Halaman Utama</MenuItem>
                </Link>
                <Link href={routes.myAccount.href} passHref>
                  <MenuItem as="a">Akun Saya</MenuItem>
                </Link>
                <MenuItem onClick={onClickLogOut}>Keluar</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </Container>
    </Box>
  );
};

export default AdminNav;
