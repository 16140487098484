import { DEFAULT_ERROR_MESSAGE } from './constants';
import { Role } from './interfaces';

export function isAdmin(role: Role | null) {
  const adminRoles: Role[] = ['Admin', 'Super Admin'];

  if (role) return adminRoles.includes(role);

  return false;
}

export function isSuperAdmin(role: Role | null) {
  return role === 'Super Admin';
}

export function parseError(error: any) {
  switch (error.code) {
    case 'auth/account-exists-with-different-credential':
      return 'Akun ini telah terdaftar dengan metode pendaftaran yang lain.';

    case 'auth/email-already-in-use':
      return 'Alamat email telah terdaftar.';

    case 'auth/invalid-verification-code':
    case 'auth/missing-verification-code':
      return 'Kode verifikasi salah.';

    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'Alamat email atau password salah.';

    case undefined:
      return error.message;

    default:
      return DEFAULT_ERROR_MESSAGE;
  }
}
