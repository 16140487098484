import React from 'react';
// Modules
import {
  Box, Container, SimpleGrid, Text, VStack,
} from '@chakra-ui/react';
import Link from 'next/link';
// Utils
import routes from '../../utils/routes';

const Footer: React.FC = () => (
  <Box as="footer" color="white" bgColor="secondary.500" paddingY="4">
    <Container maxW="container.lg" p="4">
      <SimpleGrid columns={[1, 3]} spacing="8">
        <VStack alignItems="flex-start">
          <Text fontSize="lg" fontWeight="bold">
            Utama Stationery
          </Text>
          <Link href={routes.about.href} passHref>
            <Text as="a">Tentang Utama</Text>
          </Link>
          <Link href={routes.blog.href} passHref>
            <Text as="a">Blog</Text>
          </Link>
          <Text
            as="a"
            href="https://www.instagram.com/utamastationerytulungagung"
            rel="noopener noreferrer"
            target="_blank"
          >
            Instagram
          </Text>
          <Text
            as="a"
            href="https://shopee.co.id/utamastationerytulungagung"
            rel="noopener noreferrer"
            target="_blank"
          >
            Shopee
          </Text>
        </VStack>
        <VStack alignItems="flex-start">
          <Text fontSize="lg" fontWeight="bold">
            Bantuan dan Panduan
          </Text>
          <Link href={routes.contact.href} passHref>
            <Text as="a">Kontak Kami</Text>
          </Link>
          <Link href={routes.termsAndConditions.href} passHref>
            <Text as="a">Syarat dan Ketentuan</Text>
          </Link>
          <Link href={routes.privacyPolicy.href} passHref>
            <Text as="a">Kebijakan Privasi</Text>
          </Link>
        </VStack>
        <VStack alignItems="flex-start">
          <Text fontSize="lg" fontWeight="bold">
            Alamat
          </Text>
          <Text
            as="a"
            href="https://goo.gl/maps/K5a599qUSWAScK3P7"
            rel="noopener noreferrer"
            target="_blank"
          >
            Ruko Panglima Sudirman B-2
            <br />
            Jalan Hasanudin No. 29
            <br />
            Tulungagung, Jawa Timur
          </Text>
        </VStack>
      </SimpleGrid>
    </Container>
    <Container maxW="container.lg" p="4">
      <Text>&copy; 2021 Utama Stationery</Text>
    </Container>
  </Box>
);

export default Footer;
