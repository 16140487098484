import React from 'react';
// Modules
import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import Link from 'next/link';
// Contexts
import { useAuth } from '../../contexts/AuthContext';
// Utils
import { isSuperAdmin } from '../../utils/helpers';
import routes from '../../utils/routes';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const AdminMobileDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const { currentUser, role } = useAuth();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Admin Menu</DrawerHeader>
          <DrawerBody>
            <VStack alignItems="flex-start" divider={<StackDivider />} spacing="4">
              <Link href={routes.adminDashboard.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Beranda
                </Text>
              </Link>
              {isSuperAdmin(role) && (
                <Link href={routes.adminFeature.href} passHref>
                  <Text as="a" fontWeight="semibold">
                    Data Fitur
                  </Text>
                </Link>
              )}
              <Link href={routes.adminCustomer.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Data Pelanggan
                </Text>
              </Link>
              <Link href={routes.adminSale.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Data Penjualan
                </Text>
              </Link>
              <Link href={routes.adminPointUsage.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Data Penukaran Poin
                </Text>
              </Link>
              {isSuperAdmin(role) && (
                <Link href={routes.adminProduct.href} passHref>
                  <Text as="a" fontWeight="semibold">
                    Data Produk
                  </Text>
                </Link>
              )}
              <Link href={routes.adminPromotion.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Data Promo
                </Text>
              </Link>
              <Link href={routes.home.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Halaman Utama
                </Text>
              </Link>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Link href={routes.myAccount.href} passHref>
              <Avatar
                as="a"
                name={currentUser?.displayName ?? undefined}
                src={currentUser?.photoURL ?? undefined}
              />
            </Link>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AdminMobileDrawer;
