import React from 'react';
// Modules
import { Box } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
// Components
import AdminNav from './AdminNav';
import Footer from './Footer';
import Nav from './Nav';

interface Props {
  admin?: boolean;
}

const Page: React.FC<Props> = ({ admin, children }) => (
  <>
    {admin ? <AdminNav /> : <Nav />}
    <Box height="80px" />
    <main>
      <AnimatePresence>
        <motion.div
          animate="enter"
          initial="initial"
          variants={{
            enter: {
              opacity: 1,
              transition: {
                duration: 0.4,
                ease: [0.6, 1, 0.8, 1],
              },
              y: 0,
            },
            initial: {
              opacity: 0,
              y: 8,
            },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </main>
    <Footer />
  </>
);

export default Page;
