import React from 'react';
// Modules
import {
  Avatar,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import Link from 'next/link';
// Contexts
import { useAuth } from '../../contexts/AuthContext';
// Utils
import { isAdmin } from '../../utils/helpers';
import routes from '../../utils/routes';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const MobileDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const { currentUser, role } = useAuth();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack alignItems="flex-start" divider={<StackDivider />} spacing="4">
              <Link href={routes.home.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Halaman Utama
                </Text>
              </Link>
              <Text
                as="a"
                fontWeight="semibold"
                href="https://shopee.co.id/utamastationerytulungagung"
                rel="noopener noreferrer"
                target="_blank"
              >
                Belanja
              </Text>
              <Link href={routes.promotion.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Promo
                </Text>
              </Link>
              <Link href={routes.contact.href} passHref>
                <Text as="a" fontWeight="semibold">
                  Kontak Kami
                </Text>
              </Link>
              {isAdmin(role) && (
                <Link href={routes.adminDashboard.href} passHref>
                  <Text as="a" fontWeight="semibold">
                    Admin
                  </Text>
                </Link>
              )}
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            {currentUser ? (
              <Link href={routes.myAccount.href} passHref>
                <Avatar
                  as="a"
                  name={currentUser.displayName ?? undefined}
                  src={currentUser.photoURL ?? undefined}
                />
              </Link>
            ) : (
              <>
                <Link href={routes.logIn.href} passHref>
                  <Button as="a" colorScheme="primary" mr="4" textColor="black">
                    Masuk
                  </Button>
                </Link>
                <Link href={routes.signUp.href} passHref>
                  <Button as="a" colorScheme="secondary">
                    Daftar
                  </Button>
                </Link>
              </>
            )}
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MobileDrawer;
