import React from 'react';
// Modules
import { Text } from '@chakra-ui/react';
import Link from 'next/link';
import { UrlObject } from 'url';

interface Props {
  active?: boolean;
  as?: string | UrlObject;
  href: string | UrlObject;
  rel?: string;
  target?: string;
}

const NavLink: React.FC<Props> = ({
  active, as, children, href, rel, target,
}) => {
  if (target === '_blank' && typeof href === 'string') {
    return (
      <Text
        _hover={{
          borderBottomColor: 'primary.500',
        }}
        as="a"
        borderBottomColor="transparent"
        borderBottomWidth="medium"
        borderTopColor="transparent"
        borderTopWidth="medium"
        fontWeight="semibold"
        href={href}
        rel={rel}
        target={target}
      >
        {children}
      </Text>
    );
  }

  return (
    <Link as={as} href={href} passHref>
      <Text
        _hover={{
          borderBottomColor: 'primary.500',
        }}
        as="a"
        borderBottomColor={active ? 'primary.500' : 'transparent'}
        borderBottomWidth="medium"
        borderTopColor="transparent"
        borderTopWidth="medium"
        fontWeight="semibold"
      >
        {children}
      </Text>
    </Link>
  );
};

export default NavLink;
