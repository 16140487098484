const routes = {
  about: {
    changefreq: 'weekly',
    href: '/tentang-kami',
    priority: '0.80',
  },
  adminCustomer: {
    href: '/admin/data-pelanggan',
  },
  adminCustomerInfo: {
    as: (userId: string) => `/admin/data-pelanggan/${userId}`,
    href: '/admin/data-pelanggan/[userId]',
  },
  adminDashboard: {
    href: '/admin/beranda',
  },
  adminFeature: {
    href: '/admin/data-fitur',
  },
  adminPointUsage: {
    href: '/admin/data-penukaran-poin',
  },
  adminPointUsageCreate: {
    href: '/admin/data-penukaran-poin/tukar-poin',
  },
  adminProduct: {
    href: '/admin/data-produk',
  },
  adminPromotion: {
    href: '/admin/data-promo',
  },
  adminPromotionCreate: {
    href: '/admin/data-promo/buat-baru',
  },
  adminPromotionInfo: {
    as: (promotionId: string) => `/admin/data-promo/${promotionId}`,
    href: '/admin/data-promo/[promotionId]',
  },
  adminPromotionUpdate: {
    as: (promotionId: string) => `/admin/data-promo/${promotionId}/ubah-data`,
    href: '/admin/data-promo/[promotionId]/ubah-data',
  },
  adminSale: {
    href: '/admin/data-penjualan',
  },
  adminSaleCreate: {
    href: '/admin/data-penjualan/buat-baru',
  },
  blog: {
    changefreq: 'weekly',
    href: '/blog',
    priority: '0.80',
  },
  contact: {
    changefreq: 'weekly',
    href: '/kontak-kami',
    priority: '0.80',
  },
  forgotPassword: {
    changefreq: 'weekly',
    href: '/lupa-kata-sandi',
    priority: '0.80',
  },
  home: {
    changefreq: 'weekly',
    href: '/',
    priority: '0.80',
  },
  logIn: {
    changefreq: 'weekly',
    href: '/masuk',
    priority: '0.80',
  },
  myAccount: {
    href: '/akun-saya',
  },
  privacyPolicy: {
    changefreq: 'weekly',
    href: '/kebijakan-privasi',
    priority: '0.80',
  },
  promotion: {
    changefreq: 'weekly',
    href: '/promo',
    priority: '0.80',
  },
  promotionInfo: {
    as: (promotionId: string) => `/promo/${promotionId}`,
    changefreq: 'weekly',
    href: '/promo/[promotionId]',
    priority: '0.80',
  },
  resetPassword: {
    href: '/atur-ulang-kata-sandi',
  },
  signUp: {
    changefreq: 'weekly',
    href: '/daftar',
    priority: '0.80',
  },
  termsAndConditions: {
    changefreq: 'weekly',
    href: '/syarat-dan-ketentuan',
    priority: '0.80',
  },
  updatePassword: {
    href: '/akun-saya/ubah-kata-sandi',
  },
  updatePhoneNumber: {
    href: '/akun-saya/ubah-nomor-hp',
  },
};

export function generateGeneralSitemap() {
  const mappedRoutes = Object.keys(routes)
    .filter((key) => !['promotionInfo'].includes(key))
    // @ts-ignore
    .map((key) => routes[key]);

  return mappedRoutes
    .filter((route) => !!(route.changefreq && route.priority))
    .map(
      (route) => `
      <url>
        <loc>https://www.utamastationery.com${route.href}</loc>
        <changefreq>${route.changefreq}</changefreq>
        <priority>${route.priority}</priority>
      </url>
    `,
    )
    .join('');
}

export function generatePromotionSitemap(promotionIds: string[]) {
  return promotionIds
    .map(
      (promotionId) => `
      <url>
        <loc>https://www.utamastationery.com${routes.promotionInfo.as(promotionId)}</loc>
        <changefreq>${routes.promotionInfo.changefreq}</changefreq>
        <priority>${routes.promotionInfo.priority}</priority>
      </url>
    `,
    )
    .join('');
}

export default routes;
